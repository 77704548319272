<template>
    <!-- 首页 -->
    <div ref="fatherDiv">
        <div class="conts">
            <div class="conts-left">

            </div>
            <div class="conts-middle"></div>
            <div class="conts-right">
                <div class="conts-r-gonggao">
                    <img src="../../assets/img/gonggao.png" alt="">
                    <p @click="toNoticeList" style="cursor: pointer;">公告</p>
                </div>
                <div class="conts-r-long">
                    <img src="../../assets/img/long.png" alt="">
                </div>
                <div class="conts-r-text">
                    <ul>
                        <li>
                            <div class="dian"></div>
                            <a href="">NEW FRIENDS HAD JON US!</a>
                            <span>>></span>
                        </li>
                        <li>
                            <div class="dian"></div>
                            <a href="">WE NEED YOUR HELP!</a>
                            <span>>></span>
                        </li>
                        <li>
                            <div class="dian"></div>
                            <a href="">A NEW FUNS SHOW COMMING!</a>
                            <span>>></span>
                        </li>
                    </ul>
                </div>
                <div class="conts-r-technology">
                    <p class="yello-txt">科技兽装</p>
                </div>
                <div class="conts-r-maobu">
                    <div class="maobu-tieshen">
                        <p class="yello-txt">贴身毛</p>
                    </div>
                    <div class="maobu-maobu">
                        <p class="yello-txt">毛布</p>
                    </div>
                </div>
                <div class="conts-r-make">
                    <div class="make-fengshan">
                        <p class="yello-txt">风扇定制</p>
                    </div>
                    <div class="make-fengshan">
                        <p class="yello-txt">周边制作</p>
                    </div>
                </div>
                <div class="conts-r-painter">
                    <div class="painter-list">
                        <img src="../../assets/img/zuozhe1.png" alt="">
                        <img src="../../assets/img/zuozhe2.png" alt="">
                        <img src="../../assets/img/zuozhe3.png" alt="">
                        <img src="../../assets/img/zuozhe4.png" alt="">
                    </div>
                    <div class="painter-txt">
                        <div style="flex: 1;"></div>
                        <div class="pt-right">
                            <h3 class="ptr-name">小夜</h3>
                            <div class="ptr-tiao"></div>
                            <p class="ptr-cont">幻毛是一个综合性质、面向FURRY的网站，主营业务分为销售相关产品、推广来自中国的装师和工作室，以及展示国内兽圈文化提供的互动平台。自营业务主要是3D打印和提供高效便捷的货运服务。自有物流公司和制模公司。可以制作多种产品，在3D打印这一块，不只是光固化材料，碳纤维，硅胶，甚至一些高精度模具都可以生产。另外提供贴纸、徽章，抱枕等代理加工生产。感谢您的关注</p>
                        </div>
                    </div>
                </div>
                <div class="conts-r-tiao"></div>
                <div class="conts-r-bottom">
                    <div class="share-list">
                        <div>
                            <img style="width: 37px;height: 36px;" src="../../assets/img/twitter.png" alt="">
                        </div>
                        <div>
                            <img style="width: 24px;height: 40px;" src="../../assets/img/facebook.png" alt="">
                        </div>
                        <div>
                            <img style="width: 37px;height: 44px;" src="../../assets/img/qq.png" alt="">
                        </div>
                    </div>
                    <div style="color: #E9B96B;text-align: center;">
                        <p style="font-size: 18px;margin-bottom: 5px;">该服务可能仅在部分 APPLE STORE 零售店提供，在线换购和店内换购的折抵金额可能有所不同。某些 APPLE STORE 零售店可能有不同要求。APPLE 的折抵服务合作伙伴保留出于任何原因拒绝或限制</p>
                        <p style="font-size: 18px;">任何设备 (及其数量) 的权利。如需获得有关折抵及设备回收服务的更多信息，请咨询 APPLE 的折抵服务合作伙伴。需要遵守 APPLE 的折抵服务合作伙伴的其他条款。</p>
                    </div>
                </div>
            </div>
            <div id="drag1"
            draggable="true" 
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
            @dragstart="dragstart($event)"
            @dragend="dragend($event)"
            :style="`left:${elLeft}px;top:${elTop}px;`">
                <div class="drag-close">
                    <img class="fu1" src="../../assets/img/fu1.png" alt="">
                    <p class="fu2">Catalogue</p>
                </div>
                <div class="drag-open">
                    <div class="open-top">
                        <div class="open-top-left">
                            <p>PRODUCT</p>
                            <p>公告</p>
                            <p>科技兽装</p>
                            <p>贴身毛</p>
                            <p>毛布</p>
                            <p>风扇定制</p>
                            <p>装师展示</p>
                            <p>画廊</p>
                            <p>周边制作</p>
                        </div>
                        <div class="open-top-middle">
                            <div class="middle1"></div>
                            <div class="middle2"></div>
                        </div>
                        <div class="open-top-right">
                            <img src="../../assets/img/drag1.png" alt="">
                        </div>
                    </div>
                    <div class="open-middle"></div>
                    <div class="open-foot">
                        <img src="../../assets/img/drag2.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Home',
    data(){
        return{
            initWidth: 0,   //父元素的宽-自适应值
            initHeight: 0,  //父元素的高-自适应值
            startclientX: 0,    //元素拖拽前距离浏览器的X轴位置
            startclientY: 0,    //元素拖拽前距离浏览器的Y轴位置
            elLeft: 0,  //元素的左偏移量
            elTop: 0,   //元素的上便宜量
        }
    },
    mounted(){
        this.initBodySize();
    },
    methods:{
        initBodySize(){
            this.initWidth = this.$refs.fatherDiv.clientWidth;
            this.initHeight = this.initWidth * ((1080 * 0.88) / (1920 - 1080 * 0.02));
        },
        dragstart(ev){
            console.log(ev);
            this.startclientX = ev.clientX; //记录拖拽元素初始位置
            this.startclientY = ev.clientY;
        },
        dragend(ev){
            console.log(ev);
            let x = ev.clientX - this.startclientX;  //计算偏移量
            let y = ev.clientY - this.startclientY;
            this.elLeft += x;
            if(this.elLeft < 0){
                this.elLeft = 0;
            }else if(this.elLeft + 200 > this.initWidth){
                this.elLeft = this.initWidth - 200;
            }
            this.elTop += y;
            if(this.elTop < 0){
                this.elTop = 0;
            }else if(this.elTop + 677 > document.body.scrollHeight){
                this.elTop = document.body.scrollHeight - 677;
            }
        },
        mouseenter(){

        },
        mouseleave(){

        },
        // 跳转公告列表
        toNoticeList(){
            this.$router.push({path: '/noticeList'});
        },
    }
}
</script>
<style scoped lang="less">
.head{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #24242D;
}
.head-img{
    width: 40px;
    height: 40px;
    margin-right: 35px;
}
.head-cont{
    display: flex;
    align-items: center;
}
.head-cont span{
    font-size: 20px;
    color: #636378;
    margin-left: 20px;
    cursor: pointer;
}
.head-cont span:hover{
    color: #B7B7DE;
}
.line{
    width: 2px;
    height: 20px;
    background: #E9B96B;
    margin-left: 20px;
}
.conts{
    display: flex;
    position: relative;
    background: url(../../assets/img/bg.jpg)center;
}
.conts-left{
    width: 60px;
    /* background: #24242D; */
}
.conts-middle{
    width: 10px;
    background: #000000;
}
.conts-right{
    flex: 1;
    /* background: #24242D; */
    position: relative;
}
.conts-r-gonggao{
    min-width: 1200px;
    height: 500px;
    margin: 0 50px 60px 50px;
}
.conts-r-gonggao img{
    width: 100%;
    height: 100%;
}
.conts-r-gonggao p{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #E9B96B;
    font-size: 144px;
    font-weight: 900;
    text-align: center;
}
.conts-r-long{
    position: absolute;
    top: 171px;
    right: 0;
    width: 646px;
    height: 516px;
}
.conts-r-long img{
    width: 100%;
    height: 100%;
}
.conts-r-text{
    margin-left: 190px;
}
.conts-r-text ul li{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #C59E5F;
}
.dian{
    width: 15px;
    height: 15px;
    background: #E9B96B;
    margin-right: 7px;
    border-radius: 50%;
}
.conts-r-text ul li a{
    color: #C59E5F;
    text-decoration: underline;
    width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}
.conts-r-technology{
    width: 1200px;
    height: 675px;
    margin: 20px auto;
    background: url(../../assets/img/technology.png)no-repeat center;
    background-size: cover;
    position: relative;
}
.yello-txt{
    font-size: 64px;
    font-weight: 900;
    margin-left: 30px;
    padding-top: 20px;
    color: #E9B96B;
    text-align: left;
}
.conts-r-maobu{
    width: 1200px;
    height: 543px;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
}
.maobu-tieshen{
    width: 815px;
    height: 543px;
    box-shadow: 10px 10px 0px 0px #24242D;
    margin-right: 20px;
    background: url(../../assets/img/tieshenmao.png)no-repeat center;
    background-size: cover;
}
.maobu-maobu{
    width: 365px;
    height: 543px;
    box-shadow: 10px 10px 0px 0px #24242D;
    background: url(../../assets/img/maobu.png)no-repeat center;
    background-size: cover;
}
.conts-r-make{
    width: 1200px;
    height: 439px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.make-fengshan{
    width: 590px;
    height: 439px;
    box-shadow: 10px 10px 0px 0px #24242D;
    background: url(../../assets/img/fengshan.png)no-repeat center;
    background-size: cover;
}
.conts-r-painter{
    margin-top: 100px;
    margin-left: 40px;
    display: flex;
}
.painter-list{
    margin-right: 120px;
    width: 550px;
}
.painter-list img{
    width: 270px;
    height: 203px;
    margin: 0 10px 10px 0;
}
.painter-list img:nth-child(2n){
    margin-right: 0;
}
.painter-txt{
    flex: 1;
    height: 416px;
    border-radius: 300px 0px 0px 300px;
    background: #5076D0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.conts-r-tiao{
    height: 10px;
    background: #000000;
}
#drag1{
    position: absolute;
    top: 0;
    left: 0;
}
.drag-close{
    width: 200px;
    height: 257px;
    background: #E9B96B;
    text-align: center;
}
.fu1{
    width: 160px;
    height: 160px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.fu2{
    font-size: 36px;
    font-weight: bold;
    color: #3D3D3D;
}
.drag-open{
    height: 0;
    background: #E9B96B;
    /* padding-bottom: 26px; */
    border-radius: 0 0 60px 0;
    transition: all 1s;
    overflow: hidden;
}
.open-top{
    display: flex;
    margin: 0 5px 0 10px;
}
.open-top-left{
    display: flex;
    flex-direction: column;
}
.open-top-left p{
    font-size: 18px;
    color: #3D3D3D;
    margin-bottom: 10px;
    cursor: pointer;
}
.open-top-left p:first-child{
    font-size: 24px;
    cursor: unset;
}
.open-top-middle{
    width: 4px;
    background: #4A4950;
    margin-left: 8px;
    margin-right: 6px;
}
.middle1{
    width: 4px;
    height: 6px;
    margin-top: 9px;
    background: #E9B96B;
}
.middle2{
    width: 4px;
    height: 6px;
    margin-top: 20px;
    background: #E9B96B;
}
.open-top-right img{
    width: 47px;
    height: 136px;
}
.open-middle{
    width: 170px;
    height: 2px;
    background: #4A4950;
    margin: 0 auto;
}
.open-foot{
    margin-top: 12px;
    text-align: center;
}
.open-foot img{
    width: 163px;
    height: 62px;
}
#drag1:hover .drag-open{
    height: 360px;
}
.conts-r-bottom{
    padding: 40px 0 120px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .share-list{
        margin-bottom: 40px;
        display: flex;
        div{
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-right: 60px;
            background: #E9B96B;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        div:last-child{
            margin-right: 0;
        }
    }
}
.pt-right{
    width: 600px;
    height: 342px;
    border: 5px solid #E9B96B;
    border-right: none;
    color: #E9B96B;
    display: flex;
    flex-direction: column;
    .ptr-name{
        font-size: 90px;
        font-weight: 900;
        padding: 5px 0 5px 25px;
    }
    .ptr-tiao{
        width: 100%;
        height: 5px;
        background: #E9B96B;
    }
    .ptr-cont{
        font-size: 18px;
        line-height: 1.5;
        padding: 0 32px;
        text-indent: 2em;
        margin: auto;
    }
}
</style>