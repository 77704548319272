var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"fatherDiv"},[_c('div',{staticClass:"conts"},[_c('div',{staticClass:"conts-left"}),_c('div',{staticClass:"conts-middle"}),_c('div',{staticClass:"conts-right"},[_c('div',{staticClass:"conts-r-gonggao"},[_c('img',{attrs:{"src":require("../../assets/img/gonggao.png"),"alt":""}}),_c('p',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.toNoticeList}},[_vm._v("公告")])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"conts-r-tiao"}),_vm._m(6)]),_c('div',{style:(`left:${_vm.elLeft}px;top:${_vm.elTop}px;`),attrs:{"id":"drag1","draggable":"true"},on:{"mouseenter":_vm.mouseenter,"mouseleave":_vm.mouseleave,"dragstart":function($event){return _vm.dragstart($event)},"dragend":function($event){return _vm.dragend($event)}}},[_vm._m(7),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conts-r-long"},[_c('img',{attrs:{"src":require("../../assets/img/long.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conts-r-text"},[_c('ul',[_c('li',[_c('div',{staticClass:"dian"}),_c('a',{attrs:{"href":""}},[_vm._v("NEW FRIENDS HAD JON US!")]),_c('span',[_vm._v(">>")])]),_c('li',[_c('div',{staticClass:"dian"}),_c('a',{attrs:{"href":""}},[_vm._v("WE NEED YOUR HELP!")]),_c('span',[_vm._v(">>")])]),_c('li',[_c('div',{staticClass:"dian"}),_c('a',{attrs:{"href":""}},[_vm._v("A NEW FUNS SHOW COMMING!")]),_c('span',[_vm._v(">>")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conts-r-technology"},[_c('p',{staticClass:"yello-txt"},[_vm._v("科技兽装")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conts-r-maobu"},[_c('div',{staticClass:"maobu-tieshen"},[_c('p',{staticClass:"yello-txt"},[_vm._v("贴身毛")])]),_c('div',{staticClass:"maobu-maobu"},[_c('p',{staticClass:"yello-txt"},[_vm._v("毛布")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conts-r-make"},[_c('div',{staticClass:"make-fengshan"},[_c('p',{staticClass:"yello-txt"},[_vm._v("风扇定制")])]),_c('div',{staticClass:"make-fengshan"},[_c('p',{staticClass:"yello-txt"},[_vm._v("周边制作")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conts-r-painter"},[_c('div',{staticClass:"painter-list"},[_c('img',{attrs:{"src":require("../../assets/img/zuozhe1.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/img/zuozhe2.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/img/zuozhe3.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/img/zuozhe4.png"),"alt":""}})]),_c('div',{staticClass:"painter-txt"},[_c('div',{staticStyle:{"flex":"1"}}),_c('div',{staticClass:"pt-right"},[_c('h3',{staticClass:"ptr-name"},[_vm._v("小夜")]),_c('div',{staticClass:"ptr-tiao"}),_c('p',{staticClass:"ptr-cont"},[_vm._v("幻毛是一个综合性质、面向FURRY的网站，主营业务分为销售相关产品、推广来自中国的装师和工作室，以及展示国内兽圈文化提供的互动平台。自营业务主要是3D打印和提供高效便捷的货运服务。自有物流公司和制模公司。可以制作多种产品，在3D打印这一块，不只是光固化材料，碳纤维，硅胶，甚至一些高精度模具都可以生产。另外提供贴纸、徽章，抱枕等代理加工生产。感谢您的关注")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conts-r-bottom"},[_c('div',{staticClass:"share-list"},[_c('div',[_c('img',{staticStyle:{"width":"37px","height":"36px"},attrs:{"src":require("../../assets/img/twitter.png"),"alt":""}})]),_c('div',[_c('img',{staticStyle:{"width":"24px","height":"40px"},attrs:{"src":require("../../assets/img/facebook.png"),"alt":""}})]),_c('div',[_c('img',{staticStyle:{"width":"37px","height":"44px"},attrs:{"src":require("../../assets/img/qq.png"),"alt":""}})])]),_c('div',{staticStyle:{"color":"#E9B96B","text-align":"center"}},[_c('p',{staticStyle:{"font-size":"18px","margin-bottom":"5px"}},[_vm._v("该服务可能仅在部分 APPLE STORE 零售店提供，在线换购和店内换购的折抵金额可能有所不同。某些 APPLE STORE 零售店可能有不同要求。APPLE 的折抵服务合作伙伴保留出于任何原因拒绝或限制")]),_c('p',{staticStyle:{"font-size":"18px"}},[_vm._v("任何设备 (及其数量) 的权利。如需获得有关折抵及设备回收服务的更多信息，请咨询 APPLE 的折抵服务合作伙伴。需要遵守 APPLE 的折抵服务合作伙伴的其他条款。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drag-close"},[_c('img',{staticClass:"fu1",attrs:{"src":require("../../assets/img/fu1.png"),"alt":""}}),_c('p',{staticClass:"fu2"},[_vm._v("Catalogue")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drag-open"},[_c('div',{staticClass:"open-top"},[_c('div',{staticClass:"open-top-left"},[_c('p',[_vm._v("PRODUCT")]),_c('p',[_vm._v("公告")]),_c('p',[_vm._v("科技兽装")]),_c('p',[_vm._v("贴身毛")]),_c('p',[_vm._v("毛布")]),_c('p',[_vm._v("风扇定制")]),_c('p',[_vm._v("装师展示")]),_c('p',[_vm._v("画廊")]),_c('p',[_vm._v("周边制作")])]),_c('div',{staticClass:"open-top-middle"},[_c('div',{staticClass:"middle1"}),_c('div',{staticClass:"middle2"})]),_c('div',{staticClass:"open-top-right"},[_c('img',{attrs:{"src":require("../../assets/img/drag1.png"),"alt":""}})])]),_c('div',{staticClass:"open-middle"}),_c('div',{staticClass:"open-foot"},[_c('img',{attrs:{"src":require("../../assets/img/drag2.png"),"alt":""}})])])
}]

export { render, staticRenderFns }